var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"dashboard-page"},[_c('v-row',{staticClass:"d-flex justify-space-between mt-10 mb-6",attrs:{"no-gutters":""}},[_c('Title',{attrs:{"title":"الصفحة الرئيسية"}})],1),("Error: Request failed with status code 403" == _vm.DashboardData)?_c('v-alert',{attrs:{"dir":"rtl","border":"right","colored-border":"","type":"error","elevation":"2"}},[_vm._v(" عذرا ليس لديك صلاحيات لمشاهدة البيانات او التعديل عليها ")]):_vm._e(),("Error: Request failed with status code 403" != _vm.DashboardData)?_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-card',{attrs:{"width":"100%"}},[_c('v-card-title',{staticClass:"flex-nowrap"},[_c('p',{staticClass:"text-decoration-underline"},[_vm._v("التقييمات :")]),_c('v-spacer')],1),_c('v-card-text',[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"success","outlined":""}},[_vm._v(" أنواع التقييم ")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex",staticStyle:{"height":"300px"},attrs:{"lg":"8","md":"10","cols":"12"}},[(
                    _vm.apexLoading &&
                    _vm.mock.apexPieRating.options &&
                    _vm.generatePieSeries(6).length
                  )?_c('ApexChart',{attrs:{"type":"donut","options":_vm.mock.apexPieRating.options,"series":_vm.generatePieSeries(6)}}):_vm._e()],1)],1)],1)],1)],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-card',{attrs:{"width":"100%"}},[_c('v-card-title',{staticClass:"flex-nowrap"},[_c('p',{staticClass:"text-decoration-underline"},[_vm._v("المكاتب :")]),_c('v-spacer')],1),_c('v-card-text',[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"success","outlined":""}},[_vm._v(" أنواع العقود ")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex",staticStyle:{"height":"300px"},attrs:{"lg":"8","md":"10","cols":"12"}},[(
                    _vm.apexLoading &&
                    _vm.mock.apexPieRating.options &&
                    _vm.generatePieSeries(2).length
                  )?_c('ApexChart',{attrs:{"type":"donut","options":_vm.mock.apexPieContract.options,"series":_vm.generatePieSeries(2)}}):_vm._e()],1)],1)],1)],1)],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"width":"100%"}},[_c('v-card-title',{staticClass:"flex-nowrap"},[_c('p',{staticClass:"text-decoration-underline"},[_vm._v("العقارات :")]),_c('v-spacer')],1),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex",staticStyle:{"height":"300px"},attrs:{"lg":"6","md":"6","cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"lg":"4","md":"6","cols":"12"}},[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"success","outlined":""}},[_vm._v(" أنواع العقارات ")])],1),_c('v-col',{staticClass:"d-flex",staticStyle:{"height":"300px"},attrs:{"lg":"8","md":"10","cols":"12"}},[(
                        _vm.apexLoading &&
                        _vm.mock.apexPieRating.options &&
                        _vm.generatePieSeries(0).length
                      )?_c('ApexChart',{attrs:{"type":"donut","options":_vm.mock.apexPieEstates.options,"series":_vm.generatePieSeries(0)}}):_vm._e()],1)],1)],1),_c('v-col',{staticClass:"d-flex",staticStyle:{"height":"300px"},attrs:{"lg":"6","md":"6","cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"lg":"4","md":"6","cols":"12"}},[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"success","outlined":""}},[_vm._v(" أنواع العروض ")])],1),_c('v-col',{staticClass:"d-flex",staticStyle:{"height":"300px"},attrs:{"lg":"8","md":"10","cols":"12"}},[(
                        _vm.apexLoading &&
                        _vm.mock.apexPieRating.options &&
                        _vm.generatePieSeries(1).length
                      )?_c('ApexChart',{attrs:{"type":"donut","options":_vm.mock.apexPieSaleOrRent.options,"series":_vm.generatePieSeries(1)}}):_vm._e()],1)],1)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"width":"100%"}},[_c('v-card-title',{staticClass:"flex-nowrap"},[_c('p',{staticClass:"text-decoration-underline"},[_vm._v("الطلبات :")]),_c('v-spacer')],1),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex",staticStyle:{"height":"300px"},attrs:{"lg":"6","md":"6","cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"lg":"4","md":"6","cols":"12"}},[_c('v-chip',{staticClass:"ma-2 pa-1",attrs:{"color":"success","outlined":""}},[_vm._v(" عدد طلبات العقارات ")])],1),_c('v-col',{staticClass:"d-flex",staticStyle:{"height":"300px"},attrs:{"lg":"8","md":"10","cols":"12"}},[(
                        _vm.apexLoading &&
                        _vm.mock.apexPieRating.options &&
                        _vm.generatePieSeries(3).length
                      )?_c('ApexChart',{attrs:{"type":"donut","options":_vm.mock.apexPieEstates.options,"series":_vm.generatePieSeries(3)}}):_vm._e()],1)],1)],1),_c('v-col',{staticClass:"d-flex",staticStyle:{"height":"300px"},attrs:{"lg":"6","md":"6","cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"lg":"4","md":"6","cols":"12"}},[_c('v-chip',{staticClass:"ma-2 pa-1",attrs:{"color":"success","outlined":""}},[_vm._v(" عدد طلبات العروض ")])],1),_c('v-col',{staticClass:"d-flex",staticStyle:{"height":"300px"},attrs:{"lg":"8","md":"12","cols":"12"}},[(
                        _vm.apexLoading &&
                        _vm.mock.apexPieRating.options &&
                        _vm.generatePieSeries(4).length
                      )?_c('ApexChart',{attrs:{"type":"donut","options":_vm.mock.apexPieSaleOrRent.options,"series":_vm.generatePieSeries(4)}}):_vm._e()],1)],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }