var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("الإشعارات الواردة")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.notifications,"item-value":"id"},scopedSlots:_vm._u([{key:"item.offerNumber",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"primary","to":("/estates/" + (item.offerNumber))}},[_vm._v(" "+_vm._s(item.offerNumber)+" ")])]}},{key:"item.userName",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.openUserDetails(item)}}},[_vm._v(" "+_vm._s(item.userName)+" ")])]}},{key:"item.seen",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.seen === '1' ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.seen === "1" ? "mdi-check-circle" : "mdi-alert-circle")+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.createdAt))])]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.markAsRead(item)}}},[_vm._v(_vm._s(item.message))])]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.isUserModalOpen),callback:function ($$v) {_vm.isUserModalOpen=$$v},expression:"isUserModalOpen"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("معلومات المستخدم")])]),_c('v-card-text',[_c('div',[_c('strong',[_vm._v("الاسم:")]),_vm._v(" "+_vm._s(_vm.userDetails.firstName)+" "+_vm._s(_vm.userDetails.lastName)+" ")]),_c('div',[_c('strong',[_vm._v("رقم الهاتف:")]),_vm._v(" "+_vm._s(_vm.userDetails.phone))]),_c('div',[_c('strong',[_vm._v("البريد الإلكتروني:")]),_vm._v(" "+_vm._s(_vm.userDetails.email || "غير متوفر")+" ")])]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.closeUserModal}},[_vm._v("إغلاق")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }