export default {
  light: {
    primary: "#0f4c81",
    secondary: "#F5C400",
    error: "#FF4081",
    warning: "#FFC260",
    success: "#2e9671",
    info: "#9013FE",
    textColor: "#B9B9B9",
    iconColor: "rgba(255, 255, 255, 0.35)",
    grayBold: "#4a4a4a",
  },
};
