var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Switcher',{attrs:{"label":"الأحصائيات","attr":"statistical","model":_vm.form.statistical},on:{"switcher":function (val) {
                  _vm.form.statistical = val.value;
                  _vm.$v.form.statistical.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Switcher',{attrs:{"label":"المكاتب","attr":"estate_offices","model":_vm.form.estate_offices},on:{"switcher":function (val) {
                  _vm.form.estate_offices = val.value;
                  _vm.$v.form.estate_offices.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Switcher',{attrs:{"label":" العقارات","attr":"estates","model":_vm.form.estates},on:{"switcher":function (val) {
                  _vm.form.estates = val.value;
                  _vm.$v.form.estates.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Switcher',{attrs:{"label":"الطلبات العقارية","attr":"estate_orders","model":_vm.form.estate_orders},on:{"switcher":function (val) {
                  _vm.form.estate_orders = val.value;
                  _vm.$v.form.estate_orders.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Switcher',{attrs:{"label":"التفيمات","attr":"ratings","model":_vm.form.ratings},on:{"switcher":function (val) {
                  _vm.form.ratings = val.value;
                  _vm.$v.form.ratings.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Switcher',{attrs:{"label":"أشعارات المكتب","attr":"office_notifications","model":_vm.form.office_notifications},on:{"switcher":function (val) {
                  _vm.form.office_notifications = val.value;
                  _vm.$v.form.office_notifications.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Switcher',{attrs:{"label":"أشعارات المستخدم","attr":"costumers_notifications","model":_vm.form.costumers_notifications},on:{"switcher":function (val) {
                  _vm.form.costumers_notifications = val.value;
                  _vm.$v.form.costumers_notifications.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Switcher',{attrs:{"label":"ثوابت النظام","attr":"system_variables","model":_vm.form.system_variables},on:{"switcher":function (val) {
                  _vm.form.system_variables = val.value;
                  _vm.$v.form.system_variables.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Switcher',{attrs:{"label":"العمليات","attr":"operations","model":_vm.form.operations},on:{"switcher":function (val) {
                  _vm.form.operations = val.value;
                  _vm.$v.form.operations.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Switcher',{attrs:{"label":"المستخدمين","attr":"users","model":_vm.form.users},on:{"switcher":function (val) {
                  _vm.form.users = val.value;
                  _vm.$v.form.users.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Switcher',{attrs:{"label":"الموظفين","attr":"employees","model":_vm.form.employees},on:{"switcher":function (val) {
                  _vm.form.employees = val.value;
                  _vm.$v.form.employees.$touch();
                }}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('div',[_c('Button',{attrs:{"color":"blue darken-1","type":"submit","label":"حفظ"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }